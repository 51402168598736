<template>
  <div style="text-align:center;">
  <content-loader

                    viewBox="0 0 400 160"
                    :speed=this.speed
                    :width=this.width
                    :height=this.height
                    primaryColor="#ffffff"
                    secondaryColor="#e3e3e3"
  >
    <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
    <rect x="48" y="26" rx="3" ry="3" width="52" height="6" />
    <rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
    <circle cx="20" cy="20" r="20" />
  </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from 'vue-content-loader'

export default {
  name: "LoadingContabilidade",
  components: {
    ContentLoader,
  },
  props: {
    width: {
      default: "400",

    },
    height : {
      default: "160",

    },
    speed: {
      default: 1
    }
  },
  data() {
    return {
      Dwidth: this.width ,
      Dheight: this.height ,
    }

  }
}
</script>

<style scoped>
content-loader {
  display: block;
  margin: auto;
}
</style>