<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>

    <div class="row">
      <div class="col-xl-12">
        <WidgetEasy />
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <DealStatusUsuarios />
      </div>
      <div class="col-sm-6">
        <div class="row">
          <div class="col-lg-3 col-md-6" v-for="(item, index) of data_list" :key="index">
            <div class="card">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="avatar-sm flex-shrink-0">
                      <span class="avatar-title bg-light text-primary rounded-circle fs-3">
                        <i :class="`${item.icon} align-middle`"></i>
                      </span>
                  </div>
                  <div class="flex-grow-1 ms-3">
                    <p class="text-uppercase fw-semibold fs-12 text-muted mb-1">{{ item.label }}</p>
                    <h1 class="mb-0">
                       <span class="counter-value">
                         <count-to :duration="3000" :startVal='0' :endVal=item.counter ></count-to>
                        </span>
                    </h1>
                  </div>
                </div>
              </div>
              <!-- end card body -->
            </div>
            <!-- end card -->
          </div>
          <!-- end col -->
        </div>
      </div>
    </div>


  </Layout>
</template>
<script>

import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../../app.config";
import axios from "axios";
import {  CountTo } from "vue3-count-to";
import  WidgetEasy  from "@/components/widgeteasy.vue";

import DealStatusUsuarios from "@/components/dashusuarios-status.vue";
export default {
  name: 'Dashboard EASY UX',
  page: {
    title: "Dashboard EASY UX",
    meta: [{name: "description", content: appConfig.description}],
  },
  data() {
    return {
      title: "Dashboard EASY UX",
      items: [
        {
          text: "Dashboard EASY UX",
          href: "/",
        },
        {
          text: "Dashboard EASY UX",
          active: true,
        },
      ],
      data_list : {

      }
    };
  },
  components: {
    Layout,
    PageHeader,
    CountTo,
    DealStatusUsuarios,
    WidgetEasy
  },
  mounted() {

    this.ConsultaDashboard();
  },
  methods: {
    async ConsultaDashboard() {

      try {

        const result = await axios.get(process.env.VUE_APP_API + '/v1/dashboard');

        this.data_list = result.data;

        console.log(result);

      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>


