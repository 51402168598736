<template>

  <div class="card">
    <div class="card-header align-items-center d-flex">
      <h4 class="card-title mb-0 flex-grow-1">Top 5 usuários</h4>
      <div class="flex-shrink-0">
        <div class="dropdown card-header-dropdown">

          <a class="text-reset dropdown-btn"
             href="#"
             data-bs-toggle="dropdown"
             aria-haspopup="true"
             aria-expanded="false" >

            <span class="text-muted">
              Filtro
              <i class="mdi mdi-chevron-down ms-1"></i>
            </span>

          </a>

          <div class="dropdown-menu dropdown-menu-end">
            <a class="dropdown-item" href="#" v-bind:value="'hoje'" v-on:click="selecionarValor">Hoje</a>
            <a class="dropdown-item" href="#" v-bind:value="'ultimaSemana'" v-on:click="selecionarValor">Última semana</a>
            <a class="dropdown-item" href="#" v-bind:value="'ultimoMes'" v-on:click="selecionarValor">Último Mês</a>
            <a class="dropdown-item" href="#" v-bind:value="'esteAno'" v-on:click="selecionarValor">Este ano</a>
          </div>

        </div>
      </div>
    </div>
    <!-- end card header -->

    <div class="card-body">
      <div class="table-responsive table-card">
        <table
          class="table table-borderless table-hover table-nowrap align-middle mb-0"
        >
          <thead class="table-light">
            <tr class="text-muted">
              <th scope="col">Perfil</th>
              <th scope="col">Nome</th>
              <th scope="col" style="width: 20%">Último acesso</th>

              <th scope="col" style="width: 16%">Status</th>
              <th scope="col" style="width: 12%">Faturado</th>
            </tr>
          </thead>

          <LoadingDashUsuarios v-if="!Pagelista" :speed="1" :width="400" :height="auto"
                               style="margin-top: 25%;"></LoadingDashUsuarios>
          <tbody v-else>
            <tr v-for="(item, index) of Pagelista" :key="index">
              <td>
                <img
                    :src="`${item.Photo}`"
                    alt=""
                    class="avatar-xs rounded-circle me-2"
                />
                <a :href="`usuario/usuario/${item.Id}`" class="text-body fw-medium" >
                  {{item.Descricao}}
                </a>
              </td>
              <td>{{item.Nome}}</td>
              <td>{{item.DataHoraAcesso}}</td>

              <td>
                <span :class="'badge badge-soft-'+item.badgeClass+' p-2'">{{item.status}}</span>
              </td>
              <td>
                <div class="text-nowrap">R$
                  <count-to :startVal='0'
                            :endVal=item.total_nfse
                            :decimals='2'
                            :options="{separator: '.', decimal: ',', decimalPlaces: 2}"
                            :duration='3000'></count-to>
                </div>
              </td>
            </tr>
          </tbody>
          <!-- end tbody -->
        </table>
        <!-- end table -->
      </div>
      <!-- end table responsive -->
    </div>
    <!-- end card body -->
  </div>
  <!-- end card -->
</template>

<script itemscope>
import axios from "axios";
import {CountTo} from "vue3-count-to";
import LoadingDashUsuarios from "@/components/loading/LoadingDashUsuarios.vue";

export default {
  components:{
    LoadingDashUsuarios,
    CountTo
  },
  props: [
    'lista'
  ],
  data() {
    return {
      valorSelecionado: '',
      Pagelista : this.lista,
    }

  },
  setup() {
    return {
      dealsStatus: [
        {
          id: 1,
          name: "Absternet LLC",
          date: "Sep 20, 2021",
          img: require("@/assets/images/users/avatar-1.jpg"),
          representativeName: "Donald Risher",
          badgeClass: "success",
          status: "Deal Won",
          statusValue: "$100.1K",
        },
        {
          id: 2,
          name: "Raitech Soft",
          date: "Sep 23, 2021",
          img: require("@/assets/images/users/avatar-2.jpg"),
          representativeName: "Sofia Cunha",
          badgeClass: "warning",
          status: "Intro Call",
          statusValue: "$150K",
        },
        {
          id: 3,
          name: "William PVT",
          date: "Sep 27, 2021",
          img: require("@/assets/images/users/avatar-3.jpg"),
          representativeName: "Luis Rocha",
          badgeClass: "danger",
          status: "Stuck",
          statusValue: "$78.18K",
        },
        {
          id: 4,
          name: "Loiusee LLP",
          date: "Sep 30, 2021",
          img: require("@/assets/images/users/avatar-4.jpg"),
          representativeName: "Vitoria Rodrigues",
          badgeClass: "success",
          status: "Deal Won",
          statusValue: "$180K",
        },
        {
          id: 5,
          name: "Apple Inc.",
          date: "Sep 30, 2021",
          img: require("@/assets/images/users/avatar-6.jpg"),
          representativeName: "Vitoria Rodrigues",
          badgeClass: "info",
          status: "New Lead",
          statusValue: "$78.9K",
        },
      ],
    };
  },
  methods: {
    async ConsultaDashboardUsuarios() {

      try {
        const result = await axios.get(process.env.VUE_APP_API + '/v1/dashboard/usuarios');
        this.Pagelista = result.data;
      } catch (e) {
        console.log(e);
      }
    },
    async selecionarValor(event) {

      try {

        this.Pagelista = false;

        this.valorSelecionado = event.target.getAttribute('value');
        const result = await axios.get(process.env.VUE_APP_API + '/v1/dashboard/usuarios?filter='+this.valorSelecionado,{
          filtro :this.valorSelecionado
        });
        this.Pagelista = result.data;
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.ConsultaDashboardUsuarios();
  }
};
</script>
